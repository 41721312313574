body {
  margin: 0px;
  scrollbar-color: rgba(0, 0, 0, 0.06) #f7f7f7;
}

:root {
  --ff: 'Inter';
}

* {
  font-family: var(--ff);
  box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.06);
  border-radius: 999px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.15);
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.text-ellipsis-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

input[type='password'] {
  font:
    small Verdana,
    sans-serif;
  letter-spacing: 1px;
}
